<script setup lang="ts">
// vuetify
import { useDisplay } from 'vuetify'

/**
 * props
 * ==================================================================
 */
interface Props {
  justify?: string
  timezone: string
}
withDefaults(defineProps<Props>(), {
  justify: 'center'
})

/**
 * vuetify
 * ==================================================================
 */
const { xs } = useDisplay()
</script>

<template>
  <small :class="`d-flex justify-${justify} align-center ${xs ? 'x-small' : ''}`">
    All times displayed in
    <v-chip
      :class="xs ? 'ma-1' : 'ma-2'"
      color="secondary-lighten-1"
      :size="xs ? 'x-small' : 'small'"
      variant="flat"
    >
      <client-only>
        {{ timezone }}
      </client-only>
    </v-chip>
  </small>
</template>

<style scoped>
.x-small {
  font-size: 0.6rem;
}
</style>
